import '../css/Menu.css';
import { Link } from 'react-router-dom';

import MenuAmburguer from './MenuAmburguer';

const Menu = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div id="secao1" className="Menu">
      <dl className="dl-horizontal">
        <li> 
          <a href="#secao1" onClick={() => scrollToSection('secao1')}>Home</a> 
        </li>
        <li> 
          <a href="#secao2" onClick={() => scrollToSection('secao2')}>Vârcolaci</a>
        </li>
        <li> 
          <a href="#secao3" onClick={() => scrollToSection('secao3')}>Quem Somos?</a>
        </li>
        <li> 
          <a href="#secao4" onClick={() => scrollToSection('secao4')}>Equipe</a> 
        </li>
      </dl>

      <MenuAmburguer />
    </div>
  );
}

export default Menu;
