import React, { useState } from 'react';
import '../css/NossaEquipe.css';
import { FaLinkedin, FaGithub, FaInstagram, FaYoutube  } from 'react-icons/fa';

import joseImg from "../Assets/grandeJose.webp";
import arnaldoImg from "../Assets/arnaldo.png";
import denilsonImg from "../Assets/denilson.png";
import nicolas from "../Assets/Nicolas.jpg";
import josuel from "../Assets/Josuel.jpg";
import kalil from "../Assets/kalil.jpg";
import andre from "../Assets/Andre.jpg";
import jessica from "../Assets/jessica.jpeg";

const colaboradores = [
  { 
    id: '00', 
    nome: 'José', 
    sobrenome: 'Magalhães', 
    img: joseImg, 
    cargo: 'Founder/Product Owner',
    linkedin: 'https://www.linkedin.com/in/jos%C3%A9-magalh%C3%A3es-31a1a0176/', 
    github: 'https://github.com/Magah051',
    insta: 'https://www.instagram.com/magah051/',
    youtube:'https://www.youtube.com/@Magah051',
  },
  { 
    id: '01', 
    nome: 'Arnaldo', 
    sobrenome: 'Melo', 
    img: arnaldoImg, 
    cargo: 'Ilustrador / Character Designer', 
    linkedin: 'https://www.linkedin.com/in/mr-duck-3087062ab/', 
    insta: 'https://www.instagram.com/arnaldo_mello_jr/'
  },
  { 
    id: '02', 
    nome: 'Denilson', 
    sobrenome: 'Silva', 
    img: denilsonImg, 
    cargo: 'QA GAME TESTER', 
    insta: 'https://www.instagram.com/denilson_keys/'
  },
  { 
    id: '04', 
    nome: 'Nicolas', 
    sobrenome: 'Bastos', 
    img: nicolas, 
    cargo: 'Game Developer', 
    linkedin: 'https://www.linkedin.com/in/nicolas-bastos/', 
    github: 'https://github.com/NicolasBastos027',
    insta: 'https://www.instagram.com/_nicolasbastos/'
  },
  { 
    id: '05', 
    nome: 'Kalil', 
    sobrenome: 'N.Farias', 
    img: kalil, 
    cargo: 'Game Designer/Pixel Art', 
    linkedin: 'https://www.linkedin.com/in/kalil-nogueira-farias-2b11791b4/',
    insta:'https://www.instagram.com/koidetrion/'
  },
  { 
    id: '06', 
    nome: 'Josuel', 
    sobrenome: 'B.Mroczko', 
    img: josuel, 
    cargo: 'Leader Developer/Game Developer', 
    linkedin: 'https://www.linkedin.com/in/josuel-mroczko-677b36183/', 
    github: 'https://github.com/josuelbmroczko',
    insta: 'https://www.instagram.com/josuel.mroczko/'
  },
  { 
    id: '07', 
    nome: 'Andre', 
    sobrenome: 'L.R Santos', 
    img: andre, 
    cargo: 'Game Developer', 
    linkedin: 'https://www.linkedin.com/in/andreramiro19/', 
    github: 'https://github.com/andreramiro19',
    insta:'https://www.instagram.com/andre_ramiro_/',
  },
  { 
    id: '08', 
    nome: 'Jessica', 
    sobrenome: 'Rodrigues', 
    img: jessica, 
    cargo: 'Community Manager', 
    linkedin: 'https://www.linkedin.com/in/devjessicarodrigues/', 
    github: 'https://github.com/devjessicarodrigues',
    insta: 'https://www.instagram.com/itsxjazz/'
  },
];

const NossaEquipe = () => {
  const [modalData, setModalData] = useState(null);

  const openModal = (perfil) => {
    setModalData(perfil);
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <div id="secao4" className="nossa-equipe">
      <div className="perfis-de-colaboradores">
        {colaboradores.map((perfil) => (
          <div key={perfil.id} className="perfil" onClick={() => openModal(perfil)}>
            <img src={perfil.img} alt={`${perfil.nome} ${perfil.sobrenome}`} className="imagem-colaborador" />
            <h3>{perfil.nome} {perfil.sobrenome}</h3>
            <p>{perfil.cargo}</p>
          </div>
        ))}
      </div>

      {modalData && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="modal-header">
              <img src={modalData.img} alt={`${modalData.nome} ${modalData.sobrenome}`} className="modal-img" />
              <div className="modal-info">
                <h2>{modalData.nome} {modalData.sobrenome}</h2>
                <p><strong>Cargo:</strong> {modalData.cargo}</p>
                <div className='modalIco'>
  {modalData.linkedin && (
    <a href={modalData.linkedin} target="_blank" rel="noopener noreferrer">
      <FaLinkedin size={24} />
    </a>
  )}
  {modalData.github && (
    <a href={modalData.github} target="_blank" rel="noopener noreferrer">
      <FaGithub size={24} />
    </a>
  )}
  {modalData.insta && (
    <a href={modalData.insta} target="_blank" rel="noopener noreferrer">
      <FaInstagram size={24} />
    </a>
  )}
  {modalData.youtube && (
    <a href={modalData.youtube} target="_blank" rel="noopener noreferrer">
      <FaYoutube size={24} />
    </a>
  )}
</div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NossaEquipe;
